<script lang="ts" setup>
const { t } = useT();

const { open, close } = useNlcModals();

const errorMsgs = {
	notValid: t("Form not valid"),
	notCompleted: t("Must be completed")
};
const {
	email,
	password,
	socialsOtp,
	signupLoading,
	acceptTerms,
	acceptRestrictedStates,
	error,
	errorSignup,
	errorMessage,
	isAcceptTermsError,
	isAcceptRestrictedStatesError,
	isEmailShowError,
	isEmailTouched,
	isPasswordShowError,
	isPasswordTouched,
	isButtonEnabled,
	resetError,
	handleSubmit
} = useSignup({ errorMsgs, open, close });

const onSubmit = () => {
	handleSubmit();
};
</script>

<template>
	<form class="form" @submit.prevent="onSubmit">
		<template v-if="socialsOtp?.length">
			<MSocialAuth :socials="socialsOtp" theme="dark" location-tid="home" />
			<ADividerText color="var(--neutral-40)">
				<AText class="color-neutral-50" :size="10" :modifiers="['linght']">
					{{ t("or Join with Email") }}
				</AText>
			</ADividerText>
		</template>

		<AText v-if="errorSignup" :size="16" class="color-custom-22" data-tid="error-signup">
			{{ errorSignup }}
		</AText>
		<label class="field" :class="{ 'field-error': isEmailShowError }">
			<MEmailInput
				v-model="email"
				:placeholder="t('Your Email')"
				:invalid="isEmailShowError"
				clear
				autocomplete="username"
				location-tid="home-email"
				@blur="isEmailTouched = true"
				@input="resetError"
			/>
			<AAnimationSlide v-if="isEmailShowError" :active="isEmailShowError" :distance="4">
				<AText :size="12" class="color-custom-22" data-tid="home-email-validation">
					<span>{{ errorMessage || t("Please enter your email") }}</span>
				</AText>
			</AAnimationSlide>
		</label>
		<label class="field" :class="{ 'field-error': isPasswordShowError }">
			<MPasswordInput
				v-model="password"
				:placeholder="t('Enter password')"
				:invalid="isPasswordShowError"
				autocomplete="current-password"
				location-tid="home-password"
				@blur="isPasswordTouched = true"
			/>
			<AAnimationSlide v-if="isPasswordShowError" :active="isPasswordShowError" :distance="4">
				<AText :size="12" class="color-custom-22" data-tid="home-password-validation">
					{{ password.length ? t("Password must be at least 6 characters long") : t("Please create a password") }}
				</AText>
			</AAnimationSlide>
		</label>

		<div class="content-checkboxs">
			<MCheckbox
				v-model="acceptRestrictedStates"
				:invalid="isAcceptRestrictedStatesError"
				data-tid="home-age-confirm-chk"
			>
				<AText :size="10" as="div" class="checkbox-text">
					<i18n-t keypath="I am at least 18 years old and not a resident of the {link}.">
						<template #link>
							<NuxtLink to="/page/agreement/#restricted" target="_blank" data-tid="home-age-rules-link">
								<AText :size="10" :modifiers="['link', 'bold']">{{ t("Restricted states") }}</AText>
							</NuxtLink>
						</template>
					</i18n-t>
				</AText>
			</MCheckbox>
			<MCheckbox v-model="acceptTerms" :invalid="isAcceptTermsError" data-tid="home-terms-confirm-chk">
				<AText :size="10" as="div" class="checkbox-text">
					<i18n-t
						keypath="I accept the NoLimitCoins {link1} and {link2} (including, for the California residents the {link3})."
					>
						<template #link1>
							<NuxtLink to="/page/agreement" target="_blank" data-tid="home-agreement-rules-link">
								<AText :size="10" :modifiers="['link', 'bold']">{{ t("Terms of Use") }}</AText>
							</NuxtLink>
						</template>
						<template #link2>
							<NuxtLink to="/page/privacy" target="_blank" data-tid="home-privacy-rules-link">
								<AText :size="10" :modifiers="['link', 'bold']">{{ t("Privacy Policy") }}</AText>
							</NuxtLink>
						</template>
						<template #link3>
							<NuxtLink to="/page/california-privacy-notice" target="_blank" data-tid="home-california-rules-link">
								<AText :size="10" :modifiers="['link', 'bold']">
									{{ t("California Privacy Notice") }}
								</AText>
							</NuxtLink>
						</template>
					</i18n-t>
				</AText>
			</MCheckbox>
			<AAnimationSlide v-if="error === 'agree'" :active="error === 'agree'" :distance="4">
				<AText :size="12" class="error" data-tid="home-rules-disagree-error">{{ errorMessage }}</AText>
			</AAnimationSlide>
		</div>

		<AButton variant="primary" :disabled="!isButtonEnabled" class="button" data-tid="home-create-account-btn">
			<NuxtIcon v-if="signupLoading" name="28/spinner" class="spinner" />
			<AText v-else :modifiers="['uppercase', 'bold']">{{ t("Create account") }}</AText>
		</AButton>

		<AText :size="12" :modifiers="['center']">
			<i18n-t keypath="Already have Accout? {link}">
				<template #link>
					<AText :size="12" :modifiers="['link']" @click="open('LazyOModalLogin')">
						{{ t("Sign In") }}
					</AText>
				</template>
			</i18n-t>
		</AText>
	</form>
</template>

<style lang="scss" scoped>
.form {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.form-title {
	padding-right: 32px;
}

.button {
	height: 50px;

	&.remind {
		margin-top: 10px;
	}

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
}

.field {
	position: relative;

	&:deep(.input-group) {
		background: var(--neutral-15);

		.icon-button path,
		.icon-button svg {
			stroke: var(--neutral-70) !important;
		}
	}

	&:deep(.input-group input) {
		--a-input-padding: 13px 10px;
	}

	&:deep(.input-group:not(.invalid) input) {
		color: var(--neutral-100);
	}
}

.error {
	color: var(--custom-22);
}

.field-error {
	margin-bottom: 4px;
}

.forgot {
	color: var(--custom-3);
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		text-decoration: none;
	}
}

.text-flex {
	display: flex;
	justify-content: center;
	gap: 4px;
}

.spinner {
	font-size: 28px;
}

.link:hover {
	text-decoration: inherit;
}

.checkbox-text {
	padding-top: 2px;
}

.content-checkboxs {
	display: flex;
	flex-direction: column;
	gap: 16px;

	label {
		--a-checkbox-border-radius: 4px;
		align-items: center;
		gap: 15px;
	}

	:deep(.pulse) {
		display: inline-flex;
	}

	.play-animation {
		margin-top: -16px;
	}
}
</style>
